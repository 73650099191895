import styled from '@emotion/styled';

const Card = styled.div`
  width: 34%;
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: stretch;
`;

const CardImage = styled.img`
  width: 90%;
  height: auto;
  object-fit: contain;
`;

const CardTitle = styled.div`
  font-size: 3.5rem;
  text-align: center;
  font-family: poppins;
  margin: 0;
  font-weight: bold;
  color: #3e3e3e;
  line-height: 4.5rem;
`;

const CardDescription = styled.div`
  font-size: 1.8rem;
  text-align: center;
  line-height: 1.5em;
  font-weight: 300;
  margin-top: 2rem;
  margin-bottom: 2rem;
`;

type Props = {
  imageUrl: string;
  title: string;
  description: string;
};

export default (props: Props) => (
  <Card>
    <CardImage src={props.imageUrl} alt={props.title} />
    <CardTitle>{props.title}</CardTitle>
    <CardDescription>{props.description}</CardDescription>
  </Card>
);
