import React from 'react';
import { Field, ErrorMessage, Formik, FormikConfig } from 'formik';
import styled from '@emotion/styled';

const FormInput = styled(Field)`
  color: green;
  flex-grow: 1;
  padding: 1rem 2rem;
  background: 0 0;
  border: 1px solid #d5dbe0;
  border-radius: 0.8rem;
  min-width: 250px;
  font-weight: bold;
  font-family: 'Raleway';
  font-size: 1.5rem;
  color: #000;
`;

const FormLabel = styled.label`
  display: block;
  padding: 0.5rem 0;
  font-weight: bold;
`;

const FormFieldErrorMessage = styled.div`
  min-height: 1.6rem;
  padding: 0.5rem;
  font-size: 1.4rem;
  font-weight: bold;
  color: #dc1414;
`;

const FormInputButtonGroup = styled.div`
  display: flex;
  > input {
    border-right: none !important;
    border-radius: 0.8rem 0 0 0.8rem;
  }
  > button {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
`;

type FormInputButtonGroupProps = {
  children: any;
};

const FormInputGroup = (props: FormInputButtonGroupProps) => (
  <FormInputButtonGroup>{props.children}</FormInputButtonGroup>
);

const FormContainer = styled.form``;

type ErrorMessageProps = {
  name: string;
};

const FieldErrorMessage = (props: ErrorMessageProps) => (
  <FormFieldErrorMessage>
    <ErrorMessage {...props} />
  </FormFieldErrorMessage>
);

const FormFieldGroup = styled.div``;

type FieldGroupProps = {
  onChange?: () => void;
  onBlur?: () => void;
  value?: any;
  name: string;
  autoComplete?: string;
  label?: string;
  type?: string;
  hidden?: boolean;
};

type FieldGroupWithButtonProps = {
  onChange?: () => void;
  onBlur?: () => void;
  value?: any;
  name: string;
  autoComplete?: string;
  label?: string;
  type?: string;
  hidden?: boolean;
  children: React.ReactNode;
};

const FieldGroup = (props: FieldGroupProps) => {
  const { name, label, hidden, type = 'text', ...rest } = props;
  return (
    <FormFieldGroup hidden={hidden}>
      {label ? <FormLabel htmlFor={name}>{label}</FormLabel> : ''}
      <FormInput {...rest} name={name} component={type === 'textarea' ? 'textarea' : 'input'} />
      <FieldErrorMessage {...rest} name={name} />
    </FormFieldGroup>
  );
};

const FieldGroupWithButton = (props: FieldGroupWithButtonProps) => {
  const { children, name, label, hidden, type = 'text', ...rest } = props;
  return (
    <FormFieldGroup hidden={hidden}>
      {label ? <FormLabel htmlFor={name}>{label}</FormLabel> : ''}
      <FormInputButtonGroup>
        <FormInput {...rest} name={name} component={type === 'textarea' ? 'textarea' : 'input'} />
        {children}
      </FormInputButtonGroup>
      <FieldErrorMessage {...rest} name={name} />
    </FormFieldGroup>
  );
};

const Form = (props: FormikConfig<any>) => <Formik {...props} />;

Form.Container = FormContainer;
Form.ErrorMessage = FieldErrorMessage;
Form.FieldGroup = FieldGroup;
Form.FieldGroupWithButton = FieldGroupWithButton;
Form.Input = FormInput;
Form.InputButtonGroup = FormInputGroup;

export default Form;
