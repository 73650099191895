import React, { MouseEventHandler } from 'react';
import styled from '@emotion/styled';
import { Hero } from '../hero';
import { Button } from '../button';

const ArticleContainer = styled.div`
  position: relative;
  margin: 0 17rem;
  margin-right: 12rem;
  padding: 12rem 0;
`;

const ArticleContent = styled.div`
  width: 50%;
  min-height: calc(600px - 20rem);
`;

const ArticleDate = styled.div`
  font-family: poppins;
  color: #6d6c6c;
`;

const ArticleTitle = styled.div`
  font-size: 6rem;
  font-family: poppins;
  line-height: 7.5rem;
  margin: 1.5rem 0;
  margin-bottom: 2.5rem;
  font-weight: bold;
`;

const ArticleImage = styled.img`
  height: 600px;
  position: absolute;
  right: 0;
  top: 0;
`;

type Props = {
  date: string;
  title: string;
  imageUrl?: string;
  onArticleButtonClick?: MouseEventHandler<any>;
};
const defaultImageUrl = 'https://d33wubrfki0l68.cloudfront.net/b4043857fd4653d3c25b2aa13e38db7344019108/8c572/images/news/pitch-investment.png';
export default (props: Props) => {
  const { date, title, imageUrl = defaultImageUrl } = props;
  return (
    <Hero>
      <ArticleContainer>
        <ArticleContent>
          <ArticleDate>{date}</ArticleDate>
          <ArticleTitle>{title}</ArticleTitle>
          <Button onClick={() => {}}>Read Article</Button>
        </ArticleContent>
        <ArticleImage src={imageUrl} alt="Article" />
      </ArticleContainer>
    </Hero>
  );
};
