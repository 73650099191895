import styled from '@emotion/styled';

const HeroContainer = styled.section`
  display: flex;
  justify-content: center;
  position: relative;
  z-index: -1;
  margin-top: -8rem;
`;

const HeroVideo = styled.video`
  max-width: 1200px;
  width: 100%;
  height: 55rem;
  border: 1px solid #fff;
  box-shadow: inset 0 0 0 1px #fff;
`;

type Props = {
  videoUrl?: string;
};

const defaultVideoUrl = 'https://d33wubrfki0l68.cloudfront.net/8068a02fdadfdc02d2355743a561c63d14668af2/7ed0e/videos/hero.mp4';

export default (props: Props) => {
  const { videoUrl = defaultVideoUrl } = props;
  return (
    <HeroContainer>
      <HeroVideo autoPlay loop muted playsInline src={videoUrl} />
    </HeroContainer>
  );
};
