import styled from '@emotion/styled';
import { ReactChildren, ReactChild } from 'react';
import Video from './hero.video';

const HeroContainer = styled.section`
  min-height: 50rem;
  position: relative;
  margin: 4rem 0;
  margin-bottom: 6rem;
`;

const HeroContainerBackground = styled.div`
  position: absolute;
  min-width: 100vw;
  left: -2rem;
  top: 0;
  bottom: 0;
  background: #e9e0ff;
  margin: auto;
  z-index: -1;
  @media (min-width: 1520px) {
    left: calc((1520px - 100vw) / 2);
    right: 0;
    width: 100vw;
  }
`;

type Props = {
  children?: ReactChildren | ReactChild;
};

const Hero = (props: Props) => {
  const { children } = props;
  return (
    <HeroContainer>
      <HeroContainerBackground />
      {children}
    </HeroContainer>
  );
};

Hero.Video = Video;

export default Hero;
