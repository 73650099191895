import React from 'react';
import { Global, css } from '@emotion/core';

const styles = css`
  html {
    overflow-x: hidden;
    overflow-y: scroll;
    font-size: 62.5%;
    touch-action: manipulation;
  }
  body {
    margin: 0;
    padding: 0;
    font-family: 'Poppins', sans-serif;
    font-size: 1.6rem;
  }
`;

export default () => <Global styles={styles} />;
