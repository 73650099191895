import React from 'react';
import styled from '@emotion/styled';

import { Navbar } from '@base';
import { StaticQuery, graphql } from 'gatsby';
import { ContentfulNavigation } from '@queryTypes/contentfulNavigation';
import GlobalStyle from '../../../global/styles'

const AppWrapper = styled.div`
  max-width: 1520px;
  margin: 0 auto;
	padding: 0 2rem;
  font-family: 'Raleway';
`;

type Props = {
  children: React.ReactNode;
};
type Navigation = {
  contentfulNavigation: ContentfulNavigation;
};

export default ({ children }: Props) => {
  return (
	<StaticQuery
    query={navigationQuery}
    render={(data: Navigation) => {
      const { contentfulNavigation } = data;

      return (
				<>
          <GlobalStyle />
          <AppWrapper>
            <Navbar contentfulNavigation={contentfulNavigation} />
            {children}
          </AppWrapper>
				</>
      );
    }}
	/>
	);
};

export const navigationQuery = graphql`
  {
    contentfulNavigation {
      id
      title
      links {
        title
        targetUrl
      }
    }
  }
`;
