import styled from '@emotion/styled';
import { Link } from 'gatsby';
import { ContentfulNavigation } from '@queryTypes/contentfulNavigation';

type Props = {
  contentfulNavigation: ContentfulNavigation;
};

const NavWrapper = styled.nav`
  display: flex;
  justify-content: space-between;
  padding: 4rem 0;
`;

const Menu = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const MenuLink = styled(Link)`
  color: #717171;
  font-weight: bold;
  margin-left: 5rem;
  opacity: 0.8;
  text-decoration: none;
  .active,
  &:hover {
    color: #6d48e5;
  }
`;

const MenuBrand = styled(MenuLink)`
  color: #000;
  margin-left: 0;
  font-size: 3.5rem;
  font-family: Poppins;
`;

const Navbar = ({ contentfulNavigation }: Props) => (
  <NavWrapper>
    <MenuBrand to="/">Progressive</MenuBrand>
    <Menu>
      <MenuLink to="/">Home</MenuLink>
      {contentfulNavigation.links.map(link => (
        <MenuLink key={link.title} to={link.targetUrl}>
          {link.title}
        </MenuLink>
      ))}
    </Menu>
  </NavWrapper>
);

export default Navbar;
