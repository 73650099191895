import React, { MouseEventHandler, HTMLProps } from 'react';
import styled from '@emotion/styled';

const Button = styled.button`
  border: none;
  outline: none;
  background: #6d48e5;
  color: #fff;
  font-family: 'Raleway';
  font-weight: bold;
  font-size: 1.6rem;
  padding: 1rem 3.5rem;
  border-radius: 0.8rem;
  cursor: pointer;
  transition: all 0.15s ease-in;
  &:hover,
  &:active {
    box-shadow: 0 4px 4px rgba(103, 110, 144, 0.05), 0 2px 2px rgba(103, 110, 144, 0.05);
    background: #7f5ee8;
  }
`;

interface Props extends HTMLProps<HTMLButtonElement> {
  children: string;
  onClick: (cb: any) => void | null;
}

const Btn = ({ onClick, children, ...rest }: Props) => (
  <Button onClick={onClick} {...rest}>
    {children}
  </Button>
);

// Todo: Add btn with icon etc. before export
export default Btn;
